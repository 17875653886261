###
This should be used by page components only
###
import { clearAllBodyScrollLocks } from 'body-scroll-lock'
export default

	# Scroll to hashes when a new page is loaded
	mounted: -> @$wait 0, @scrollToHash

	watch:

		# Scroll to anchors in response to links on the same page
		'$route.hash': -> @scrollToHash()

	methods:

		# Scroll to the anchor referenced in the hash if it's valid
		scrollToHash: ->
			return unless @$route.hash
			return unless anchor = @$route.hash.substr 1
			return unless el = document.querySelector "[data-anchor='#{anchor}']"

			# Clear the hash to reduce confusion if clicking a button again
			@$router.replace
				path: @$route.path
				query: @$route.query
			.catch (e) -> # Silently catch "NavigationDuplicated" errors

			# Wait a tick for body scroll lock to be removed
			clearAllBodyScrollLocks()
			@$wait 50, => @$scrollbar.scrollTo el, 0
