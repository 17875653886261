###
Additions for smooth scroll support at page level
###
import pageMixin from 'kiva/mixins/page-mixin'
import handleAnchors from './handle-anchors'
export default

	mixins: [ pageMixin, handleAnchors ]

	mounted: ->

		# Using destroy + init rather than "update" because "update" resulted in the
		# scroll position being wrong during the transition.  If I switch to a page
		# transition that involves scrolling to the top of the page, I think update
		# could make more sense.
		@$scrollbar?.destroy()

		# I got the best results after waiting a tick
		@$defer => @$scrollbar?.init()

