










import BlockList, { loadBlockData } from '~/components/global/blocks/block-list'
import pageMixin from '~/mixins/page-mixin'
import getTower from '~/queries/pages/tower.gql'
homeSlug = '__home__'
export default
	name: 'Tower'

	mixins: [ pageMixin ]

	components: { BlockList }

	# Title / SEO
	head: ->
		title: (title = (@page.metaTitle or @page.title))
		meta: [
			@$metaTag 'og:title', title
			@$metaTag 'description', @page.metaDescription
			@$metaTag 'og:image', @page.metaImage
			@$metaTag 'robots', @page.robots?.join ', '
		].filter (tag) -> !!tag?.content

	# Fetch data
	asyncData: (context) ->
		{ app: {$axios, $craft, $notFound }, route, params, payload } = context

		# Don't query if hmr related
		return $notFound() if params.tower?.match /__webpack_hmr/

		# Get data
		page = payload || await $craft.getEntry
			query: getTower
			variables: slug: params.tower || homeSlug
		return $notFound() unless page

		# Hydrate some blocks with additional data so that it gets SSRed
		await loadBlockData context, page.blocks

		# Set data
		return { page }

